import React from 'react'
import moment from 'moment'
import 'moment/locale/fr'

const PostMatchesRaw = ({ event_date, homeTeam, score, awayTeam, round = '', venue, statusShort }) => {
  
  // const array = round.split('-')
  // const matchday = (array.length > 1) ? array[1].replace(' ', 'J') : round

  let date = ''
  if (statusShort === 'TBD') {
    date = moment(event_date).format('dddd Do MMMM YYYY')
  } else {
    date = moment(event_date).format('LLLL')
  }

  const matchday = round.replace('Regular Season - ', 'J')
  return (
    <tr>
      <td>{matchday}</td>
      <td className="homeTeam">{homeTeam.team_name}</td>
      <td className="fulltime">
        <div>
          {score ? score.fulltime : ''}
        </div>
        <div className="halftime">
        {score.halftime ? `(${score.halftime})` : ''}
        </div>
      </td>
      {score.extratime &&
        <td className="extratime">pro {score.extratime}</td>
      }
      {score.penalty &&
        <td className="penalty">pen {score.penalty}</td>
      }
      <td className="awayTeam">{awayTeam.team_name}</td>
      <td><div>{date}</div><div>{venue}</div></td>
    </tr>
  )
}

export default PostMatchesRaw
