import React, { Children } from 'react'

import PostSection from '../PostSection'

const PostMatches = ({ children, title }) => {
  return (
    <PostSection title={title} section="matchs">
      <div className="col-12 col-md-10">
        <div className="single-post-text table-responsive">
          <table class="table table-hover">
            <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col" className="homeTeam">Domicile</th>
                  <th scope="col" className="fulltime">Score (mt)</th>
                  <th scope="col">Extérieur</th>
                  <th scope="col">Date et Lieu</th>
                </tr>
              </thead>
            <tbody>{children}</tbody>
          </table>
        </div>
      </div>
    </PostSection>
  )
}

export default PostMatches
