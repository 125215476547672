import { graphql } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'

import SEO from '../components/SEO'
import Layout from '../components/Layout'
import PostMatches from '../components/Matches/PostMatches'
import PostMatchesRaw from '../components/Matches/PostMatchesRaw'

export default ({ data, pageContext }) => {
  const matches = data.allFootMatch.edges
  const competition = data.footCompetition

  const startDate = new Date(competition.season_start)
  const endDate = new Date(competition.season_end)
  const title = `OM calendrier / résultats ${
    competition.name
  } ${startDate.getFullYear()}/${endDate.getFullYear()}`

  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <SEO />
      <PostMatches title={title}>
        {matches.map(({ node: match }) => (
          <PostMatchesRaw {...match} />
        ))}
      </PostMatches>
    </Layout>
  )
}

export const query = graphql`
  query($competitionId: String!) {
    allFootMatch(filter: { competition: { id: { eq: $competitionId } } }) {
      edges {
        node {
          competition {
            id
          }
          awayTeam {
            team_name
          }
          homeTeam {
            team_name
          }
          event_date
          status
          statusShort
          score {
            fulltime
            halftime
            penalty
            extratime
          }
          round
          venue
        }
      }
    }
    footCompetition(id: { eq: $competitionId }) {
      id
      is_current
      league_id
      season
      season_start
      season_end
      name
    }
  }
`
